<!--  -->
<template>
  <div class='consult-detail'>
    <div class="page-way">
      <span @click="$router.push('Home')">首页</span>
      <div> > 常见问题</div>
    </div>
    <div class="msg">
      <div class="nav">
        <div>ABOUT</div>
        <div>常见问题</div>
      </div>
      <div class="content">
        <h3>{{info.title}}</h3>
        <div class="time">{{info.add_time}}</div>
        <img :src="info.image" v-if="info.image" alt="">
        <p v-html="info.text"></p>
      </div>
    </div>
    <h4 style="margin: 50px 0 30px">其它问题</h4>
    <div class="consult-arr">
      <div v-for="(item, index) in list" :key="index" @click="handleItem(item.id)">
        <div class="consult-card">
          <div>{{item.years[2]}}</div>
          <span>{{item.years[0]}}-{{item.years[1]}}</span>
        </div>
        <div>
          <h4>{{item.title}}</h4>
          <p>{{item.describe}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/my"

export default {
  components: {},
  data() {
    return {
      list: [],
      id: '',
      info: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.id = this.$route.query.id
    this.getConsult()
    this.getConsultMsg()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getConsultMsg () {
      Api.getConsultMsg({id: this.id}).then(res => {
        if (res.data.code == 1) {
          this.info = res.data.data
          document.getElementById("app").scrollTop = 0
        } else {
          this.$message.error("常见问题详情获取失败")
        }
      })
    },
    getConsult () {
      Api.getConsult({
        page: 1,
        limit: 3,
        status: '常见问题'
      }).then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
          this.list.forEach(item => {
            item.text = item.text.slice(0, 85) + '...'
            item.years = item.add_time.split(" ")[0].split("-")
          })
        } else {
          this.$message.error("常见问题获取失败")
        }
      })
    },
    handleItem (id) {
      this.id = id
      this.getConsultMsg()
    }
  },
}
</script>

<style lang='less' scoped>
.consult-detail {
  background-color: #fff;
  padding: 30px;
  padding-right: 60px;
  .page-way {
    margin: 0 0 15px;
    display: flex;
    color: #888;
    span {
      color: #409EFF;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  .msg {
    display: flex;
    margin-top: 20px;
    .nav {
      width: 200px;
      height: 200px;
      background-color: #06B487;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      > div {
        color: #e3e3e3;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    .content {
      flex: 1;
      margin-left: 20px;
      border-bottom: 5px solid #2D6438;
      .time {
        margin-top: 20px;
        font-size: 13px;
      }
      img {
        margin-top: 20px;
        max-width: 90%;
      }
      p {
        width: 92%;
        padding-right: 10px;
        max-height: 1200px;
        overflow-y: auto;
        margin-bottom: 40px;
        margin-top: 20px;
        word-break: break-all;
        white-space: pre-wrap;
        text-indent: 30px;
        &::-webkit-scrollbar{
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track{
          background: rgb(239, 239, 239);
          border-radius:2px;
        }
        &::-webkit-scrollbar-thumb{
          background: #bfbfbf;
          border-radius:10px;
        }
        &::-webkit-scrollbar-thumb:hover{
          background: #333;
        }
        &::-webkit-scrollbar-corner{
          background: #179a16;
        }
      }
    }
  }
  .consult-arr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      padding: 20px;
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      cursor: pointer;
      .consult-card {
        margin-right: 20px;
        background-color: #ffff;
        border: 1px solid #e3e3e3;
        border-radius: 2px;
        padding: 10px;
        div {
          font-size: 32px;
          text-align: right;
          color: #000;
        }
        span {
          color: #999;
        }
      }
      > div:last-child {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      p {
        color: #999999;
        width: 80%;
        word-break: break-all;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
</style>